@import '../../../style/Variables.scss';

#AK_iFrame{
    width: 100%;
    max-width: 275px;
    min-height: 322px;
}

.row-error-credit-card-service{
    margin-top: 28px;
    display: flex;
    align-items: center;
}

.row-error-credit-card-service span{
    margin-left: 5px;
}

.no-bottom-margin{
    margin-bottom: 0;
}

.credit-card-info-section{
    text-align: left;
    padding: $sectionsPadding;
    background-color: $sectionsBgColor;
}

.iframe-with-spinner{
    background:url('spinner.gif') center center no-repeat;
    background-size: 40px auto;
}

div.cctype div.row.cctype-radio .input-column {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    vertical-align: middle;
}


div.cctype div.row.cctype-radio .input-column input {
    min-height: 25px;
    vertical-align: middle;
    width: auto;
}

div.cctype div.row.cctype-radio .input-column label {
    margin: auto;
    margin-left: 1px;
}

.cctype-subtext {
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    text-indent: 5px;
}

div.cctype div.row h3 {
    margin-top: 30px;
    margin-bottom: 20px;
}

.row.cctype-radio {
    margin-left: 10px;
}