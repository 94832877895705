@import '../../../style/Variables.scss';
@import '../../../style/Buttons.scss';

.custom-button{
    @extend %base-button-full;
    width: auto;
    height: 50px;
    padding-left: 12px;
    padding-right: 12px;
}

.custom-button:hover{
    @extend %base-button-full-hover;
}

.custom-button:active{
    @extend %base-button-full-pressed;
}

.custom-button[disabled]{
    @extend %base-button-disabled;
}