@import './Variables.scss';

%base-button-full {
    background-color: $mainButtonFullColor;
    color: $mainButtonFullTextColor;
    border-radius: 3px;
    box-shadow: $mainButtonFullBoxShadow;
    border: none;
    cursor: pointer;
    font-family: $fontOne;
    font-size: 17px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: center;
    transition: background-color 0.2s;
 }
 
%base-button-full-hover {
    background-color: $mainButtonFullHoverColor;
    box-shadow: $mainButtonFullHoverBoxShadow;
}

%base-button-full-pressed {
    background-color: $mainButtonFullPressedColor;
    box-shadow: $mainButtonFullPressedBoxShadow;
}

%base-button-outline {
    background-color: $mainButtonOutlineColor;
    border: $mainButtonOutlineBorder;
    color: $mainButtonOutlineTextColor;
    border-radius: 3px;
    cursor: pointer;
    font-family: $fontOne;
    font-size: 17px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: center;
    transition: border 0.2s;
}
  
%base-button-outline-hover {
    border: $mainButtonOutlineHoverBorder;
}

%base-button-outline-pressed {
    border: $mainButtonOutlinePressedBorder;
}

%base-button-disabled {
    background-color: $mainButtonDisabledColor;
    border: $mainButtonDisabledBorder;
    font-style: $mainButtonDisabledFontStyle;
    color: $mainButtonDisabledTextColor;
    box-shadow: $mainButtonDisabledBoxShadow;
}