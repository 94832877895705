@import '../../style/Variables.scss';

.page-not-found{

    margin-bottom: 1px;
    width: calc(100% - 30px);
    background-color: $sectionsBgColor;
    padding: 12px 15px;
    text-align: center;
    color: $mainTextColor;
    line-height: 100%;
}