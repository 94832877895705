@import '../../../style/Variables.scss';

.App-header {
    background-color: $headerBgColor;
    height: calc(82px - 3vh);
    font-size: calc(10px + 2vmin);
    color: $secondairyTextColor;
    padding: 1.5vh $mainComponentHorizontalPadding;
}

.App-header-wrapper{
    margin: 0 auto;
    max-width: $mainComponentWidthMinusPadding;
}

.App-logo {
    padding: 4px 0;
    height: 51px;
    pointer-events: none;
    float: left;
}
  
.App-lang-btn {
    margin-top: 2px;
    width: 50px;
    height: 50px;
    float: right;
    background-color: $mainButtonOutlineColor;
    border: 1px solid $secondaryLightColor;
    color: $secondaryTextColor;
    font-family: $fontOne;
    font-size: 14px;
    font-weight: 600;
    border-radius: 1px;
    cursor: pointer;
    outline: none;
}
  
.App-lang-btn:hover {
    /* background-color: $sectionsBgColor; */
    border: 1px solid $secondaryTextColor;
}
  
.App-lang-btn:active { 
    -moz-box-shadow:    0 0 4px rgba(0,0,0,0.3);
    -webkit-box-shadow: 0 0 4px  rgba(0,0,0,0.3);
    box-shadow:         0 0 4px  rgba(0,0,0,0.3);
}
  