@import '../../style/Variables.scss';

.page-maintenance{

    margin-bottom: 1px;
    width: calc(100% - 30px);
    background-color: $sectionsBgColor;
    padding: 12px 15px;
    text-align: center;
    color: $mainTextColor;
    line-height: 100%;
}

.page-maintenance pre{
    display: inline-block;
    padding: 3px 6px;
    background-color: white;
}

.page-maintenance p{
    line-height: 22px;
}