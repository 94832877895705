@import '../../../style/Variables.scss';

.form-agreement-section {
    text-align: left;
    font-weight: 500;
    letter-spacing: 0;
    padding: 35px 15px;
    background-color: $rowContainerBgColor;
    font-size: 14px;
}

.consent-section {
    margin-top: 16px;
    display: flex;
}


