@import '../../../style/Variables.scss';
@import '../../../style/Buttons.scss';

.exit-page-button{
    @extend %base-button-full;
    width: 100%;
    height: 50px;
}

.exit-page-button:hover{
    @extend %base-button-full-hover;
}

.exit-page-button:active{
    @extend %base-button-full-pressed;
}

.exit-page-button[disabled]{
    @extend %base-button-disabled;
}