
@media only screen and (max-width: 1500px) {
    #AK_iFrame{
        min-height: 330px;
    }
}
@media only screen and (max-width: 1498px) {
    #AK_iFrame{
        min-height: 340px;
    }
}
@media only screen and (max-width: 1312px) {
    #AK_iFrame{
        min-height: 322px;
    }
}
@media only screen and (max-width: 1170px) {
    #AK_iFrame{
        min-height: 342px;
    }
}
@media only screen and (max-width: 832px) {
    #AK_iFrame{
        min-height: 322px;
    }
}
@media only screen and (max-width: 692px) {
    #AK_iFrame{
        min-height: 340px;
    }
}
@media only screen and (max-width: 445px) {
    #AK_iFrame{
        min-height: 360px;
    }
}
@media only screen and (max-width: 442px) {
    #AK_iFrame{
        min-height: 380px;
    }
}

@-moz-document url-prefix() { 
    #AK_iFrame{
        min-height: 343px;
    }
}