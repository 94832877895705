@import '../../../style/Variables.scss';

footer{
    width: auto;
    padding: 60px $mainComponentHorizontalPadding;
    text-align: left;
    color: $footerTextColor;
}

.footer-wrapper{
    margin: 0 auto;
    max-width: $mainComponentWidthMinusPadding;
}

footer h2{
    margin: 0;
    font-family: $fontTwo;
}

footer .footer-sub-title{
    margin-top: 36px;
    margin-bottom: 0;
}

footer .footer-last-line{
    margin-top: 36px;
    margin-bottom: 0;
}