@import 'style/Variables.scss';
@import 'style/Util.scss';
@import 'style/Dev.scss';

html, body{
  background-color: $mainBgColor;
  color: $primaryTextColor;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.left-side-pane h2, .left-side-pane h3, .left-side-pane h4, .left-side-pane h5, .left-side-pane h6, 
.right-side-pane h2, .right-side-pane h3, .right-side-pane h4, .right-side-pane h5, .right-side-pane h6{
  margin-top: 10px;
  font-family: $fontTwo;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 0;
}

.pane-wrapper{
  margin: 0 auto;
  max-width: $mainComponentWidth;
  padding: 0 $mainComponentHorizontalPadding;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.left-side-pane{
  flex: 1 0 $paneDivisionRightPourcent;
  min-width: 264px;
  margin-bottom: 14px;
}

.left-side-pane h1{
  margin-top: 0;
  color: $textTitleColor;
  font-family: $fontTwo;
  font-size: 35px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.left-side-pane h2{
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

@media only screen and (max-width: 1366px) {
  
  .left-side-pane h2{
    font-size: 28px;
  }
    
  .left-side-pane h1{
    font-size: 32px;
  }

}

.right-side-pane{
  flex: 1 0 $paneDivisionLeftPourcent;
}

.product-infos{
 /*  padding-right: 30px; */
}

.row{
  margin-bottom: 10px;
}

.form {
  position: relative;
  margin: 60px auto;

}

@media only screen and (max-width: 1000px) {
  .form {
    margin: 30px auto;
  }
}

.input-column{
  width: 47.5%;
  display: inline-block;
  vertical-align: top;
}

.input-column-small{
  width: 23.75%;
  display: inline-block;
  vertical-align: top;
}

.input-column:nth-child(odd){
  margin-right: 4.8%;
}

.column{
  width: 50%;
  display: inline-block;
}

label{
  position: static;
  display: block;
  margin: 36px 0 12px;
  font-family: $fontOne;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 700;
  color: #1b1b1b;
}

input{
  display: block;
  background: #fff;
  font-weight: normal;
  max-width: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  min-height: 36px;
  border: 1px solid transparent;
  border-color: transparent transparent #97a1b0 transparent;
  font-size: 1rem;
  padding: 0 12px 2px;
  -webkit-transition: all .2s;
  border-radius: 0;
  transition: all .2s;
  font-size: 14px;
  margin-bottom: 0;
  width: calc(100% - 24px);
}

input[type=checkbox]{
  vertical-align: top;
  background: url('./style/icones/checkbox-unchecked.svg') no-repeat;
  -webkit-appearance:unset;
  border: none;
  min-width: 20px;
  width: 25px!important;
  height: 25px;
  display: inline-block;
  min-height: 25px;
  margin: 0;
}

input[type=checkbox]:checked{
  background: url('./style/icones/checkbox-checked.svg') no-repeat;
  -webkit-appearance:unset;
}

label.label-consent{
  margin: 0 0 0 12px;
  display: inline-block;
  font-family: $fontOne;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

button[disabled]{
  cursor: not-allowed;
  /* filter: grayscale(120%); */
}

.big-margin-bottom{
  margin-bottom: 32px;
}
 
/* input[type=checkbox] + label
{
background: url('/assets/images/checkbox-unchecked.svg') no-repeat;
background-size: 16px 16px;
height: 16px;
width: 16px;
display:inline-block;
padding: 0 0 0 0px;
}
input[type=checkbox]:checked + label
{
  background: url('/assets/images/checkbox-checked.svg') no-repeat;
height: 16px;
width: 16px;
display:inline-block;
padding: 0 0 0 0px;
} */

.section{
  margin-bottom: 16px;
}

.credit-card-info-section, .form-agreement-section, .confirmation-section{
  margin-bottom: 32px;
}


#loading-screen-backdrop{
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
}

#loading-screen-backdrop:after{
  content:"";
  background-image: url('./style/icones/spinner.gif');
  background-size: 40px 40px;
  width: 40px;
  height: 40px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn-container {
  display: flex;
/*   flex-flow: row wrap; */
  justify-content: space-between;
}

.session-timeout-popup-backdrop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

.session-timeout-popup {
  position: fixed;
  z-index: 999;
  padding: 10px 20px;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 1px 4px #000;
}