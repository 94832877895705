@import '../../../style/Variables.scss';

.contact-us{
    width: auto;
    background-color: $contactUsBgColor;
    padding: 60px $mainComponentHorizontalPadding;
    text-align: left;
}


.contact-us-wrapper{
    margin: 0 auto;
    max-width: $mainComponentWidthMinusPadding;
}

  
.contact-us h2{
    font-family: $fontTwo;
    margin-top: 0;
    margin-bottom: 24px;
}

.contact-us .send-mail{
    margin-top: 27px;
    display : flex;
    align-items : center;
}

.contact-us .send-mail img.envelope{
    width: 33px;
}
.contact-us .send-mail img.arrow-img{
    margin-left: -3px;
    width: 20px;
}

.contact-us .send-mail{
    width: auto;
    text-decoration: none;
    color: $secondaryTextColor;
    font-family: $fontOne;
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.41;
    letter-spacing: normal;
}

.contact-us .action-call{
    display : flex;
    align-items : center;
    text-decoration: none;
}

.row-send-mail{
    /* max-width: 220px; */
    display: inline-block;
}

.contact-us .row-send-mail .mail-text{
    margin: 0 17px;

}

/* .contact-us .row-send-mail .arrow-img{
    color: $secondaryTextColor;
} */

.contact-us .time-frame{
    font-family: $fontOne;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.41;
    letter-spacing: normal;
}

.contact-us .row-phone{
    margin-top: 27px;
    display : flex;
    align-items : center;
}

.contact-us .row-phone .phone-number
{
    
    color: $secondaryTextColor;
    font-family: $fontOne;
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.41;
    letter-spacing: normal;
    margin: 0 17px;
}