@import '../../../style/Variables.scss';
@import '../../../style/Buttons.scss';

.submit-form-button{
    @extend %base-button-full;
    width: calc(50% - 16px);
    height: 50px;
}


.submit-form-button:hover{
    @extend %base-button-full-hover;
}

.submit-form-button:active{
    @extend %base-button-full-pressed;
}

.submit-form-button[disabled]{
    @extend %base-button-disabled;
}