@import '../../../style/Variables.scss';
@import '../../../style/Buttons.scss';

.previous-page-button{
    @extend %base-button-outline;
    width: calc(50% - 16px);
    height: 50px;
}


.previous-page-button:hover{
    @extend %base-button-outline-hover;
}

.previous-page-button:active{
    @extend %base-button-outline-pressed;
}

.previous-page-button[disabled]{
    @extend %base-button-disabled;
}