body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Colors */
/* Buttons */
/* Paddings */
/* Dimensions */
/* Division */
/* Fonts */
.float-left {
  float: left; }

.float-right {
  float: right; }

/* Dev header */
.dev-links {
  text-align: center;
  padding: 2px 0;
  background-color: #333; }

.dev-links a {
  color: white;
  text-decoration: none;
  background-color: #555;
  padding: 0 3px;
  border-radius: 2px;
  margin-right: 5px; }

.dev-links span {
  color: white;
  text-decoration: underline; }

/* Dev header - end */
html, body {
  background-color: #eff1f4;
  color: #1b1b1b;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.left-side-pane h2, .left-side-pane h3, .left-side-pane h4, .left-side-pane h5, .left-side-pane h6,
.right-side-pane h2, .right-side-pane h3, .right-side-pane h4, .right-side-pane h5, .right-side-pane h6 {
  margin-top: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 0; }

.pane-wrapper {
  margin: 0 auto;
  max-width: 1140px;
  padding: 0 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  box-sizing: border-box; }

.left-side-pane {
  -webkit-flex: 1 0 32%;
          flex: 1 0 32%;
  min-width: 264px;
  margin-bottom: 14px; }

.left-side-pane h1 {
  margin-top: 0;
  color: #003da5;
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal; }

.left-side-pane h2 {
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal; }

@media only screen and (max-width: 1366px) {
  .left-side-pane h2 {
    font-size: 28px; }
  .left-side-pane h1 {
    font-size: 32px; } }

.right-side-pane {
  -webkit-flex: 1 0 68%;
          flex: 1 0 68%; }

.product-infos {
  /*  padding-right: 30px; */ }

.row {
  margin-bottom: 10px; }

.form {
  position: relative;
  margin: 60px auto; }

@media only screen and (max-width: 1000px) {
  .form {
    margin: 30px auto; } }

.input-column {
  width: 47.5%;
  display: inline-block;
  vertical-align: top; }

.input-column-small {
  width: 23.75%;
  display: inline-block;
  vertical-align: top; }

.input-column:nth-child(odd) {
  margin-right: 4.8%; }

.column {
  width: 50%;
  display: inline-block; }

label {
  position: static;
  display: block;
  margin: 36px 0 12px;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 1.4;
  font-weight: 700;
  color: #1b1b1b; }

input {
  display: block;
  background: #fff;
  font-weight: normal;
  max-width: none;
  box-shadow: none;
  outline: none;
  min-height: 36px;
  border: 1px solid transparent;
  border-color: transparent transparent #97a1b0 transparent;
  font-size: 1rem;
  padding: 0 12px 2px;
  -webkit-transition: all .2s;
  border-radius: 0;
  transition: all .2s;
  font-size: 14px;
  margin-bottom: 0;
  width: calc(100% - 24px); }

input[type=checkbox] {
  vertical-align: top;
  background: url(/AKMWPN05/static/media/checkbox-unchecked.4ac83bed.svg) no-repeat;
  -webkit-appearance: unset;
  border: none;
  min-width: 20px;
  width: 25px !important;
  height: 25px;
  display: inline-block;
  min-height: 25px;
  margin: 0; }

input[type=checkbox]:checked {
  background: url(/AKMWPN05/static/media/checkbox-checked.01d1e88e.svg) no-repeat;
  -webkit-appearance: unset; }

label.label-consent {
  margin: 0 0 0 12px;
  display: inline-block;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal; }

button[disabled] {
  cursor: not-allowed;
  /* filter: grayscale(120%); */ }

.big-margin-bottom {
  margin-bottom: 32px; }

/* input[type=checkbox] + label
{
background: url('/assets/images/checkbox-unchecked.svg') no-repeat;
background-size: 16px 16px;
height: 16px;
width: 16px;
display:inline-block;
padding: 0 0 0 0px;
}
input[type=checkbox]:checked + label
{
  background: url('/assets/images/checkbox-checked.svg') no-repeat;
height: 16px;
width: 16px;
display:inline-block;
padding: 0 0 0 0px;
} */
.section {
  margin-bottom: 16px; }

.credit-card-info-section, .form-agreement-section, .confirmation-section {
  margin-bottom: 32px; }

#loading-screen-backdrop {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2); }

#loading-screen-backdrop:after {
  content: "";
  background-image: url(/AKMWPN05/static/media/spinner.d049f9e7.gif);
  background-size: 40px 40px;
  width: 40px;
  height: 40px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.btn-container {
  display: -webkit-flex;
  display: flex;
  /*   flex-flow: row wrap; */
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.session-timeout-popup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); }

.session-timeout-popup {
  position: fixed;
  z-index: 999;
  padding: 10px 20px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 1px 4px #000; }

/* Colors */
/* Buttons */
/* Paddings */
/* Dimensions */
/* Division */
/* Fonts */
#AK_iFrame {
  width: 100%;
  max-width: 275px;
  min-height: 322px; }

.row-error-credit-card-service {
  margin-top: 28px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.row-error-credit-card-service span {
  margin-left: 5px; }

.no-bottom-margin {
  margin-bottom: 0; }

.credit-card-info-section {
  text-align: left;
  padding: 36px 16px;
  background-color: #e0e4e9; }

.iframe-with-spinner {
  background: url(/AKMWPN05/static/media/spinner.d049f9e7.gif) center center no-repeat;
  background-size: 40px auto; }

div.cctype div.row.cctype-radio .input-column {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  vertical-align: middle; }

div.cctype div.row.cctype-radio .input-column input {
  min-height: 25px;
  vertical-align: middle;
  width: auto; }

div.cctype div.row.cctype-radio .input-column label {
  margin: auto;
  margin-left: 1px; }

.cctype-subtext {
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  text-indent: 5px; }

div.cctype div.row h3 {
  margin-top: 30px;
  margin-bottom: 20px; }

.row.cctype-radio {
  margin-left: 10px; }


@media only screen and (max-width: 1500px) {
    #AK_iFrame{
        min-height: 330px;
    }
}
@media only screen and (max-width: 1498px) {
    #AK_iFrame{
        min-height: 340px;
    }
}
@media only screen and (max-width: 1312px) {
    #AK_iFrame{
        min-height: 322px;
    }
}
@media only screen and (max-width: 1170px) {
    #AK_iFrame{
        min-height: 342px;
    }
}
@media only screen and (max-width: 832px) {
    #AK_iFrame{
        min-height: 322px;
    }
}
@media only screen and (max-width: 692px) {
    #AK_iFrame{
        min-height: 340px;
    }
}
@media only screen and (max-width: 445px) {
    #AK_iFrame{
        min-height: 360px;
    }
}
@media only screen and (max-width: 442px) {
    #AK_iFrame{
        min-height: 380px;
    }
}

@-moz-document url-prefix() { 
    #AK_iFrame{
        min-height: 343px;
    }
}
/* Colors */
/* Buttons */
/* Paddings */
/* Dimensions */
/* Division */
/* Fonts */
.adresse-wrapper {
  position: relative; }

#canada-poste-input {
  position: relative;
  width: calc(100% - 46px - 12px - 2px);
  padding-right: 46px; }

.adresse-wrapper:after {
  font-family: 'IA_icons';
  content: url(/AKMWPN05/static/media/canadapost.70de5d9c.svg);
  display: block;
  position: absolute;
  height: 30px;
  width: 24px;
  top: 5px;
  right: 12px;
  color: #ec1c2d;
  font-size: 1.714rem; }

.suggestion-wrapper {
  width: 100%;
  position: relative; }

.suggestion-wrapper ul {
  text-decoration: none;
  position: absolute;
  background-color: #fff;
  margin-top: 0;
  width: calc(100%);
  padding: 0;
  background-color: transparent;
  list-style: none outside none; }

.suggestion-wrapper ul.country-list img {
  margin-right: 5px; }

.suggestion-wrapper ul li {
  font-size: 12px;
  background-color: #fafafa;
  padding: 8px 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid #97a1b0;
  border-top: none;
  cursor: pointer; }

.suggestion-wrapper ul li[data-selected=true] {
  background-color: #003da5;
  color: #fff; }

.suggestion-wrapper ul li em {
  margin-left: 14px;
  color: #97a1b0; }

.suggestion-wrapper ul li:hover {
  background-color: #003da5;
  color: #fff; }

.suggestion-wrapper ul li.bottom-section {
  cursor: default;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #ccc;
  background-color: #fafafa;
  background-image: linear-gradient(#f9f9f9 0%, #ececec 100%);
  padding: 4px 10px;
  height: 20px; }

.suggestion-wrapper ul li.bottom-section img {
  height: 15px;
  vertical-align: bottom; }

.suggestion-wrapper ul li.bottom-section .select-country {
  margin-right: 8px;
  float: right; }

.suggestion-wrapper ul li.bottom-section:hover {
  background-color: #fafafa;
  color: black; }

.pcaflag-list-container {
  position: relative; }

.pca-country-name {
  margin-left: 25px; }

.pcaflag {
  position: absolute;
  top: 3px;
  left: 5px;
  width: 14px;
  height: 12px;
  background: url(/AKMWPN05/static/media/flags16x16.70148566.png); }

.suggestion-wrapper ul li.bottom-section .country-list-toggle {
  position: relative;
  cursor: pointer;
  width: 34px;
  height: 20px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAUCAYAAADskT9PAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3goPEAY2c/VOZwAAALJJREFUSMftljEKAjEQRV/EQ3gLy5S5iJfQQuzF2sIjWHiB7XOQ1F5AJLAEMjaD6CLYuBkFPwyEIfA/M5M/cSklLDEVkeakpRRbAeYVeMTEiNeNVoEY49s7IYQvaoGI8MlQdIC8iK6VgCXQD7h6zTcRkIDDgGuv+ednWGsdq71bYAHMgDOwazkDABdgo+c1cLUwoiMwB05WTliBVVMr9t7/xC5w6gs2AnLO1XoZ/f8Dd9wAhg84ZtjxbJ4AAAAASUVORK5CYII=);
  background-size: 34px 19px;
  color: #666;
  float: right; }

.suggestion-wrapper ul li.bottom-section .country-list-toggle:hover {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAUCAYAAADskT9PAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTc0MDM1N0Q0Q0E4MTFFNEExMTZGODEwMzE3Qzc4QUEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NTc0MDM1N0U0Q0E4MTFFNEExMTZGODEwMzE3Qzc4QUEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo1NzQwMzU3QjRDQTgxMUU0QTExNkY4MTAzMTdDNzhBQSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo1NzQwMzU3QzRDQTgxMUU0QTExNkY4MTAzMTdDNzhBQSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PqNQIyMAAADoSURBVHjaYrx9+7YnAwPDXCCWZKAveA7EySz///8HWZ4OxJvp7ABfkMcZb968+R/IYGQYGPAfFAIMAwmYGAYYUD0E9u/fT5Q6R0dH2jiA6MiH2ssEYlATQ8EWkB1Y8BZkB4AwrRxQCMQ/0Tz9EypOFwfcBuLJaA7oh4qjOIDl379/tIrmZiCOAWIJIH4KxK3IkjB7aRUCIPAJiCug7DIg/oKeCMEhQONcsBiI9YF4Oa5cQGsHgMK5CF82pLoDzM3NSSoHBrwuGK2MQCEAapn4IBeTdAIgO1+AHJACbZJJ0NkBoMIpGSDAAN+CY2AMDTYSAAAAAElFTkSuQmCC);
  background-size: 34px 19px; }

.suggestion-wrapper ul li.bottom-section .country-list-toggle.list-expended {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAUCAYAAADskT9PAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3goPEAcicDSrWwAAAK9JREFUSMftlTEKAjEQRV9E2EasBWvvkQN4Ae9gI9gJtoKFXsGj5ACpbHOXjE1gx2URC7MDkg+BYZJhfv4PGZdSwhJzEWkETAnMMMZ/WRBC+Oqc9763QET41RqBA25Dq3VNbQt2wAF4Ag9NYAoFFsC1xBdgOapAzrnW7U/AusQr4AwcAXTPWgpsivQa+5J/q6lF4A50AwJdyU/yCLefNnVPk49I93QxxjaM2jAyJfACxjtHPIKFbfEAAAAASUVORK5CYII=);
  background-size: 34px 19px; }

.suggestion-wrapper ul li.bottom-section .country-list-toggle.list-expended:hover {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAUCAYAAADskT9PAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTc0MDM1Nzk0Q0E4MTFFNEExMTZGODEwMzE3Qzc4QUEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NTc0MDM1N0E0Q0E4MTFFNEExMTZGODEwMzE3Qzc4QUEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo1NzQwMzU3NzRDQTgxMUU0QTExNkY4MTAzMTdDNzhBQSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo1NzQwMzU3ODRDQTgxMUU0QTExNkY4MTAzMTdDNzhBQSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PlfTMRsAAADdSURBVHjaYrl9+7YnAwPDXCCWZKAveA7EySz///8HWZ4OxJvp7ABfkMcZb968+R/IYGQYGPAfFAIMAwmYGAYYDHgIUNUBBw4cIEqdg4MDIgpADqAWxgJAibsPPaqR9dA6CiKBuBCILwHxAmQH0CMEeIC4C8puB2I+rCHw798/Wvm+GoiloWwJIK4D4hIQB9lOWoWAKjTokUEOVBxFD60c0A/E7GgOYIeK0yUR+uAtf5HsHJCCiGYOMDMzG1gHDNnK6Dk00Wyhs90gO1+AHJACbZJJ0NkBT0FNMoAAAwB+01dqfGikXwAAAABJRU5ErkJggg==);
  background-size: 34px 19px; }

.suggestion-wrapper ul li.bottom-section .country-list-toggle img {
  padding-left: 4px;
  margin-right: 1px;
  vertical-align: middle;
  width: 20px;
  height: auto; }

.suggestion-wrapper ul li.suggestions-next-page, .suggestion-wrapper ul li.suggestions-previous-page {
  text-align: center;
  height: 14px;
  padding: 1px; }

.suggestion-wrapper ul li.suggestions-next-page:hover, .suggestion-wrapper ul li.suggestions-previous-page:hover {
  background: #fafafa;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  color: #003da5; }

.address-suggestion-example {
  font-size: 14px;
  margin-top: 8px; }

.enter-address-manually, .enter-address-postal-box {
  font-size: 14px;
  color: #2062d4;
  margin-top: 30px;
  cursor: pointer; }

.enter-address-manually .text, .enter-address-postal-box .text {
  font-weight: 600;
  margin-left: 12px;
  text-decoration: underline; }

#manual-address-section, #manual-address-postal-box-section {
  max-height: 0;
  transition: max-height 1s;
  overflow: hidden; }

.input-address {
  margin-right: 4.8%;
  vertical-align: top; }

.suggestion-wrapper ul li.enter-address-manually-suggestion {
  cursor: default;
  color: #555;
  background-color: #ebebeb;
  padding: 4px 10px;
  height: 20px; }

.suggestion-wrapper ul li.enter-address-manually-suggestion:hover {
  color: #555;
  background-color: #ebebeb; }

.suggestion-wrapper ul li.enter-address-manually-suggestion a {
  text-decoration: underline;
  cursor: pointer; }

.row-1 .input-address:nth-child(3) {
  margin-right: 0; }

.row-2 .input-address:nth-child(4) {
  margin-right: 0; }

.row-3 .input-address:nth-child(3) {
  margin-right: 0; }

.input-address-column {
  width: 50%;
  display: inline-block; }

.input-address-column-small {
  width: 20%;
  display: inline-block; }

.input-address-column-small-medium {
  width: 22.5%;
  display: inline-block; }

.input-address-column-medium-large {
  width: 45%;
  display: inline-block; }

.input-address-column-medium {
  width: 35%;
  display: inline-block; }

@media only screen and (max-width: 660px) {
  .row-2 .input-address-column-small {
    width: 47.25%; }
  .row-2 .input-address-column-small-medium {
    width: 47.25%; }
  .row-2 .input-address-column-medium-large {
    width: 47.25%; }
  .row-2 .input-address-column-medium {
    width: 47.25%; }
  /*  .row-1 .input-address:nth-child(2){
        margin-right: 0;
    }
 */
  .row-2 .input-address:nth-child(2) {
    margin-right: 0; }
  .row-3 .input-address:nth-child(3) {
    margin-right: 0; } }

.error.info-erreur {
  box-sizing: border-box;
  position: relative;
  padding: 12px 12px 12px 48px;
  font-size: 14px;
  font-weight: 400;
  color: #1b1b1b;
  border: 1px solid #ff585d;
  display: block;
  margin-top: 0;
  line-height: 1.3;
  width: calc(100% + 2px); }

.error.info-erreur.icone-warning:before {
  fill: #ec1c2d;
  color: #ec1c2d;
  font-family: 'IA_icons';
  content: url(/AKMWPN05/static/media/error.f02d0a46.svg);
  font-size: 26px;
  position: absolute;
  left: 10px;
  top: 50%;
  width: 25px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

/* Internet Explorer */

/* Opera < 12.1 */

/* Colors */
/* Buttons */
/* Paddings */
/* Dimensions */
/* Division */
/* Fonts */
.member-info-section {
  text-align: left;
  padding: 36px 16px;
  background-color: #e0e4e9; }

.field-id-example {
  margin-top: 8px;
  font-size: 14px; }

/* Colors */
/* Buttons */
/* Paddings */
/* Dimensions */
/* Division */
/* Fonts */
/* Colors */
/* Buttons */
/* Paddings */
/* Dimensions */
/* Division */
/* Fonts */
.next-page-button {
  background-color: #003da5;
  color: white;
  border-radius: 3px;
  box-shadow: 0 1px 0 0 rgba(33, 33, 33, 0.25);
  border: none;
  cursor: pointer;
  font-family: "Open Sans";
  font-size: 17px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: center;
  transition: background-color 0.2s; }

.next-page-button:hover {
  background-color: #2062d4;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25); }

.next-page-button:active {
  background-color: #2962ff;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25); }

.next-page-button[disabled] {
  background-color: rgba(33, 33, 33, 0.25);
  border: none;
  font-style: italic;
  color: white;
  box-shadow: 0 1px 0 0 rgba(33, 33, 33, 0.25); }

.next-page-button {
  width: 100%;
  height: 50px; }


/* Colors */
/* Buttons */
/* Paddings */
/* Dimensions */
/* Division */
/* Fonts */
.review-form-field-container .column.left-aligned {
  text-align: left;
  font-weight: 700; }

.review-form-field-container .column.right-aligned {
  text-align: right; }

.information-review-section {
  background-color: #e0e4e9;
  text-align: left;
  padding: 36px 16px; }

.information-review-section h2 {
  margin-bottom: 24px; }

.review-bloc {
  padding-bottom: 36px; }

.review-bloc:nth-last-child(1) {
  padding-bottom: 0; }

.review-form-field-container {
  margin-bottom: 1px;
  width: calc(100% - 30px);
  background-color: white;
  padding: 12px 15px;
  text-align: left;
  color: black;
  line-height: 20px;
  font-size: 14px; }

.review-form-field-container .column {
  vertical-align: top; }

/* Colors */
/* Buttons */
/* Paddings */
/* Dimensions */
/* Division */
/* Fonts */
.form-agreement-section {
  text-align: left;
  font-weight: 500;
  letter-spacing: 0;
  padding: 35px 15px;
  background-color: white;
  font-size: 14px; }

.consent-section {
  margin-top: 16px;
  display: -webkit-flex;
  display: flex; }

/* Colors */
/* Buttons */
/* Paddings */
/* Dimensions */
/* Division */
/* Fonts */
/* Colors */
/* Buttons */
/* Paddings */
/* Dimensions */
/* Division */
/* Fonts */
.previous-page-button {
  background-color: transparent;
  border: solid 2px rgba(0, 61, 165, 0.35);
  color: #003da5;
  border-radius: 3px;
  cursor: pointer;
  font-family: "Open Sans";
  font-size: 17px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: center;
  transition: border 0.2s; }

.previous-page-button:hover {
  border: solid 2px #003da5; }

.previous-page-button:active {
  border: solid 2px #002688; }

.previous-page-button[disabled] {
  background-color: rgba(33, 33, 33, 0.25);
  border: none;
  font-style: italic;
  color: white;
  box-shadow: 0 1px 0 0 rgba(33, 33, 33, 0.25); }

.previous-page-button {
  width: calc(50% - 16px);
  height: 50px; }

/* Colors */
/* Buttons */
/* Paddings */
/* Dimensions */
/* Division */
/* Fonts */
/* Colors */
/* Buttons */
/* Paddings */
/* Dimensions */
/* Division */
/* Fonts */
.submit-form-button {
  background-color: #003da5;
  color: white;
  border-radius: 3px;
  box-shadow: 0 1px 0 0 rgba(33, 33, 33, 0.25);
  border: none;
  cursor: pointer;
  font-family: "Open Sans";
  font-size: 17px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: center;
  transition: background-color 0.2s; }

.submit-form-button:hover {
  background-color: #2062d4;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25); }

.submit-form-button:active {
  background-color: #2962ff;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25); }

.submit-form-button[disabled] {
  background-color: rgba(33, 33, 33, 0.25);
  border: none;
  font-style: italic;
  color: white;
  box-shadow: 0 1px 0 0 rgba(33, 33, 33, 0.25); }

.submit-form-button {
  width: calc(50% - 16px);
  height: 50px; }

/* Colors */
/* Buttons */
/* Paddings */
/* Dimensions */
/* Division */
/* Fonts */
/* Colors */
/* Buttons */
/* Paddings */
/* Dimensions */
/* Division */
/* Fonts */
.custom-button {
  background-color: #003da5;
  color: white;
  border-radius: 3px;
  box-shadow: 0 1px 0 0 rgba(33, 33, 33, 0.25);
  border: none;
  cursor: pointer;
  font-family: "Open Sans";
  font-size: 17px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: center;
  transition: background-color 0.2s; }

.custom-button:hover {
  background-color: #2062d4;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25); }

.custom-button:active {
  background-color: #2962ff;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25); }

.custom-button[disabled] {
  background-color: rgba(33, 33, 33, 0.25);
  border: none;
  font-style: italic;
  color: white;
  box-shadow: 0 1px 0 0 rgba(33, 33, 33, 0.25); }

.custom-button {
  width: auto;
  height: 50px;
  padding-left: 12px;
  padding-right: 12px; }

/* Colors */
/* Buttons */
/* Paddings */
/* Dimensions */
/* Division */
/* Fonts */
.confirmation-section {
  text-align: center;
  padding: 36px 16px;
  background-color: white;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: center;
  font-family: "Open Sans"; }

.confirmation-section h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: bold;
  margin-top: 5px; }

.confirmation-section p {
  margin-bottom: 0; }

.confirmation-section p:nth-child(even) {
  margin-top: 0; }

.phone-link-inline {
  white-space: nowrap;
  text-decoration: none;
  color: #2062d4; }

.confirmation-success-img {
  width: 96px;
  height: 96px; }

.confirmation-error-img {
  color: red;
  width: 96px;
  height: 96px; }

.reference-number-container {
  margin-top: 28px;
  margin-left: auto;
  margin-right: auto;
  width: 380px;
  background-color: #eff1f4;
  padding: 14.5px 12px;
  text-align: left;
  color: black;
  line-height: 100%;
  font-family: "Open Sans";
  font-size: 14px; }

.reference-number-container .column.left-aligned {
  text-align: left;
  font-weight: 700; }

.reference-number-container .column.right-aligned {
  text-align: right; }

/* Colors */
/* Buttons */
/* Paddings */
/* Dimensions */
/* Division */
/* Fonts */
/* Colors */
/* Buttons */
/* Paddings */
/* Dimensions */
/* Division */
/* Fonts */
.exit-page-button {
  background-color: #003da5;
  color: white;
  border-radius: 3px;
  box-shadow: 0 1px 0 0 rgba(33, 33, 33, 0.25);
  border: none;
  cursor: pointer;
  font-family: "Open Sans";
  font-size: 17px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: center;
  transition: background-color 0.2s; }

.exit-page-button:hover {
  background-color: #2062d4;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25); }

.exit-page-button:active {
  background-color: #2962ff;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25); }

.exit-page-button[disabled] {
  background-color: rgba(33, 33, 33, 0.25);
  border: none;
  font-style: italic;
  color: white;
  box-shadow: 0 1px 0 0 rgba(33, 33, 33, 0.25); }

.exit-page-button {
  width: 100%;
  height: 50px; }

/* Colors */
/* Buttons */
/* Paddings */
/* Dimensions */
/* Division */
/* Fonts */
.page-not-found {
  margin-bottom: 1px;
  width: calc(100% - 30px);
  background-color: #e0e4e9;
  padding: 12px 15px;
  text-align: center;
  color: black;
  line-height: 100%; }

/* Colors */
/* Buttons */
/* Paddings */
/* Dimensions */
/* Division */
/* Fonts */
.total-premium-section {
  text-align: left;
  padding: 36px 16px;
  background-color: #e0e4e9; }

/* #totalPremium{
    width: calc(50% - 24px);
} */

/* Colors */
/* Buttons */
/* Paddings */
/* Dimensions */
/* Division */
/* Fonts */
.App-header {
  background-color: #fff;
  height: calc(82px - 3vh);
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 1.5vh 10px; }

.App-header-wrapper {
  margin: 0 auto;
  max-width: 1120px; }

.App-logo {
  padding: 4px 0;
  height: 51px;
  pointer-events: none;
  float: left; }

.App-lang-btn {
  margin-top: 2px;
  width: 50px;
  height: 50px;
  float: right;
  background-color: transparent;
  border: 1px solid #c3d7ee;
  color: #2062d4;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  border-radius: 1px;
  cursor: pointer;
  outline: none; }

.App-lang-btn:hover {
  /* background-color: $sectionsBgColor; */
  border: 1px solid #2062d4; }

.App-lang-btn:active {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3); }

/* Colors */
/* Buttons */
/* Paddings */
/* Dimensions */
/* Division */
/* Fonts */
.contact-us {
  width: auto;
  background-color: white;
  padding: 60px 10px;
  text-align: left; }

.contact-us-wrapper {
  margin: 0 auto;
  max-width: 1120px; }

.contact-us h2 {
  font-family: "Montserrat", sans-serif;
  margin-top: 0;
  margin-bottom: 24px; }

.contact-us .send-mail {
  margin-top: 27px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.contact-us .send-mail img.envelope {
  width: 33px; }

.contact-us .send-mail img.arrow-img {
  margin-left: -3px;
  width: 20px; }

.contact-us .send-mail {
  width: auto;
  text-decoration: none;
  color: #2062d4;
  font-family: "Open Sans";
  font-size: 17px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.41;
  letter-spacing: normal; }

.contact-us .action-call {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  text-decoration: none; }

.row-send-mail {
  /* max-width: 220px; */
  display: inline-block; }

.contact-us .row-send-mail .mail-text {
  margin: 0 17px; }

/* .contact-us .row-send-mail .arrow-img{
    color: $secondaryTextColor;
} */
.contact-us .time-frame {
  font-family: "Open Sans";
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.41;
  letter-spacing: normal; }

.contact-us .row-phone {
  margin-top: 27px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.contact-us .row-phone .phone-number {
  color: #2062d4;
  font-family: "Open Sans";
  font-size: 17px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.41;
  letter-spacing: normal;
  margin: 0 17px; }

/* Colors */
/* Buttons */
/* Paddings */
/* Dimensions */
/* Division */
/* Fonts */
footer {
  width: auto;
  padding: 60px 10px;
  text-align: left;
  color: #7c878e; }

.footer-wrapper {
  margin: 0 auto;
  max-width: 1120px; }

footer h2 {
  margin: 0;
  font-family: "Montserrat", sans-serif; }

footer .footer-sub-title {
  margin-top: 36px;
  margin-bottom: 0; }

footer .footer-last-line {
  margin-top: 36px;
  margin-bottom: 0; }

/* Colors */
/* Buttons */
/* Paddings */
/* Dimensions */
/* Division */
/* Fonts */
.page-service-unavailable {
  margin-bottom: 1px;
  width: calc(100% - 30px);
  background-color: #e0e4e9;
  padding: 12px 15px;
  text-align: center;
  color: black;
  line-height: 100%; }

/* Colors */
/* Buttons */
/* Paddings */
/* Dimensions */
/* Division */
/* Fonts */
.page-maintenance {
  margin-bottom: 1px;
  width: calc(100% - 30px);
  background-color: #e0e4e9;
  padding: 12px 15px;
  text-align: center;
  color: black;
  line-height: 100%; }

.page-maintenance pre {
  display: inline-block;
  padding: 3px 6px;
  background-color: white; }

.page-maintenance p {
  line-height: 22px; }

