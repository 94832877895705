@import '../../../style/Variables.scss';

.member-info-section{
    text-align: left;
    padding: $sectionsPadding;
    background-color: $sectionsBgColor;
}

.field-id-example{
    margin-top: 8px;
    font-size: 14px;
}