@import '../../../style/Variables.scss';

.review-form-field-container .column.left-aligned{
    text-align: left;
    font-weight: 700;
}

.review-form-field-container .column.right-aligned{
    text-align: right;
}

.information-review-section{

    background-color: $sectionsBgColor;
    text-align: left;
    padding: $sectionsPadding;
}

.information-review-section h2{
    margin-bottom: 24px;
}

.review-bloc{
    padding-bottom: 36px;
}

.review-bloc:nth-last-child(1){
    
    padding-bottom: 0;
}

.review-form-field-container{
    margin-bottom: 1px;
    width: calc(100% - 30px);
    background-color: $rowContainerBgColor;
    padding: 12px 15px;
    text-align: left;
    color: $mainTextColor;
    line-height: 20px;
    font-size: 14px;
}


.review-form-field-container .column{
    vertical-align: top;
}