
/* Colors */
$headerBgColor: #fff;
$textTitleColor: #003da5;
$primaryTextColor: #1b1b1b;
$secondaryTextColor: #2062d4;
$secondaryLightColor: #c3d7ee;
$mainBgColor: #eff1f4;
$mainTextColor: black;
$secondairyTextColor: white;
$sectionsBgColor: #e0e4e9;
$rowContainerBgColor: white;
$rowContainerTextColor: black;
$contactUsBgColor: white;
$footerTextColor: #7c878e;

/* Buttons */
$mainButtonFullTextColor: white;
$mainButtonFullColor: #003da5;
$mainButtonFullBoxShadow: 0 1px 0 0 rgba(33, 33, 33, 0.25);
$mainButtonFullHoverColor: #2062d4;
$mainButtonFullHoverBoxShadow: 0 1px 0 0 rgba(0, 0, 0, 0.25);
$mainButtonFullPressedColor: #2962ff;
$mainButtonFullPressedBoxShadow: 0 1px 0 0 rgba(0, 0, 0, 0.25);

$mainButtonOutlineTextColor: #003da5;
$mainButtonOutlineOpacity: 0.35;
$mainButtonOutlineColor: transparent;
$mainButtonOutlineHoverAndPressedOpacity: 1;
$mainButtonOutlineBorder: solid 2px rgba(0,61,165, $mainButtonOutlineOpacity);
$mainButtonOutlineHoverBorder: solid 2px rgb(0,61,165);
$mainButtonOutlinePressedBorder: solid 2px #002688;

$mainButtonDisabledColor: rgba(33, 33, 33, 0.25);
$mainButtonDisabledBorder:none;
$mainButtonDisabledFontStyle: italic;
$mainButtonDisabledBoxShadow: 0 1px 0 0 rgba(33, 33, 33, 0.25);
$mainButtonDisabledTextColor: white;

/* Paddings */
$sectionsPadding: 36px 16px;
$mainComponentHorizontalPadding: 10px;

/* Dimensions */
$mainComponentWidth: 1140px;
$mainComponentWidthMinusPadding: 1120px;

/* Division */
$paneDivisionRightPourcent: 32%;
$paneDivisionLeftPourcent: 68%;

/* Fonts */
$fontOne: 'Open Sans';
$fontTwo: 'Montserrat', sans-serif;