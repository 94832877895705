@import '../../../style/Variables.scss';

.total-premium-section{
    text-align: left;
    padding: $sectionsPadding;
    background-color: $sectionsBgColor;
}

/* #totalPremium{
    width: calc(50% - 24px);
} */