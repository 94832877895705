

.error.info-erreur{
    box-sizing: border-box;
    position: relative;
    padding: 12px 12px 12px 48px;
    font-size: 14px;
    font-weight: 400;
    color: #1b1b1b;
    border: 1px solid #ff585d;
    display: block;
    margin-top: 0;
    line-height: 1.3;
    width:  calc(100% + 2px);

    // -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    // -moz-animation: fadein 1s; /* Firefox < 16 */
    // -ms-animation: fadein 1s; /* Internet Explorer */
    // -o-animation: fadein 1s; /* Opera < 12.1 */
    // animation: fadein 1s;
  }
  
  
  .error.info-erreur.icone-warning:before{
    fill: #ec1c2d;
    color: #ec1c2d;
    font-family: 'IA_icons';
    content: url('./error.svg');
    font-size: 26px;
    position: absolute;  
    left: 10px;
    top: 50%;
    width: 25px;
    transform: translateY(-50%);
  }

  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}