@import '../../../style/Variables.scss';

.adresse-wrapper{
    position: relative;
}

#canada-poste-input{
    position: relative;
    width: calc(100% - 46px - 12px - 2px);
    padding-right: 46px;
}

.adresse-wrapper:after{
    font-family: 'IA_icons';
    content: url('./images/canadapost.svg');
    display: block;
    position: absolute;
    height: 30px; //Fix a bug with some version of IE 11
    width: 24px;
    top: 5px;
    right: 12px;
    color: #ec1c2d;
    font-size: 1.714rem;
}

.suggestion-wrapper{
    width: 100%;
    position: relative;
}

.suggestion-wrapper ul{
    text-decoration: none;
    position: absolute;
    background-color: #fff;
    margin-top: 0;
    width: calc(100%);
    padding: 0;
    background-color: transparent;
    list-style: none outside none;
}

.suggestion-wrapper ul.country-list img{
    margin-right: 5px;
}


.suggestion-wrapper ul li{
    font-size: 12px;
    background-color: #fafafa;
    padding: 8px 10px;
    border: 1px solid rgba(0,0,0,0.2);
    border-bottom: 1px solid #97a1b0;
    border-top: none;
    cursor: pointer;
}

.suggestion-wrapper ul li[data-selected=true]{
    background-color: $mainButtonFullColor;
    color: #fff;
}

.suggestion-wrapper ul li em{
    margin-left: 14px;
    color: #97a1b0;
}


.suggestion-wrapper ul li:hover{
   background-color: $mainButtonFullColor;
   color: #fff;
}

.suggestion-wrapper ul li.bottom-section{
    cursor: default;
    border-top: 1px solid #fff;
    border-bottom: 1px solid#ccc;
    background-color: #fafafa;
    background-image: linear-gradient(rgb(249, 249, 249) 0%, rgb(236, 236, 236) 100%);
    padding: 4px 10px;
    height: 20px;
}
.suggestion-wrapper ul li.bottom-section img{
    height: 15px;
    vertical-align: bottom;
}
.suggestion-wrapper ul li.bottom-section .select-country{
    margin-right: 8px;
    float: right;
}
.suggestion-wrapper ul li.bottom-section:hover{
    background-color: #fafafa;
    color: black;
}

.pcaflag-list-container{
    position: relative;
}

.pca-country-name{
    margin-left: 25px;
}

.pcaflag{
    position: absolute;
    top: 3px;
    left: 5px;
    width: 14px;
    height: 12px;
    background: url('./images/flags16x16.png');
}

.suggestion-wrapper ul li.bottom-section .country-list-toggle{
    position: relative;
    cursor: pointer;
    width: 34px;
    height: 20px;
    background: url('./images/flag_clear_down.png');
    background-size: 34px 19px;
    color: #666;
    float: right;
}

.suggestion-wrapper ul li.bottom-section .country-list-toggle:hover{
    background: url('./images/flag_clear_down_hover.png');
    background-size: 34px 19px;
}

.suggestion-wrapper ul li.bottom-section .country-list-toggle.list-expended{
    background: url('./images/flag_clear_up.png');
    background-size: 34px 19px;
}

.suggestion-wrapper ul li.bottom-section .country-list-toggle.list-expended:hover{
    background: url('./images/flag_clear_up_hover.png');
    background-size: 34px 19px;
}

.suggestion-wrapper ul li.bottom-section .country-list-toggle img{
    padding-left: 4px;
    margin-right: 1px;
    vertical-align: middle;
    width: 20px;
    height: auto;
}

.suggestion-wrapper ul li.suggestions-next-page, .suggestion-wrapper ul li.suggestions-previous-page{
    text-align: center;
    height: 14px;
    padding: 1px;
}


.suggestion-wrapper ul li.suggestions-next-page:hover, .suggestion-wrapper ul li.suggestions-previous-page:hover{
    background: #fafafa;
    text-shadow: 0 0 3px rgba(0,0,0,0.3);
    color: $mainButtonFullColor;
}

.suggestion-wrapper .tradamark-bar{

}

.address-suggestion-example{
    font-size: 14px;
    margin-top: 8px;
}

.enter-address-manually, .enter-address-postal-box {
    font-size: 14px;
    color: $secondaryTextColor;
    margin-top: 30px;
    cursor: pointer;
}

.enter-address-manually .text, .enter-address-postal-box .text{
    font-weight: 600;
    margin-left: 12px;
    text-decoration: underline;
}

#manual-address-section, #manual-address-postal-box-section{
    max-height: 0;
    transition: max-height 1s;  
    overflow: hidden;
}

.input-address{
    margin-right: 4.8%;
    vertical-align: top;
}


.suggestion-wrapper ul li.enter-address-manually-suggestion{
    cursor: default;
    color: #555;
    background-color: #ebebeb;
    padding: 4px 10px;
    height: 20px;
}
.suggestion-wrapper ul li.enter-address-manually-suggestion:hover{
    color: #555;
    background-color: #ebebeb;
}

.suggestion-wrapper ul li.enter-address-manually-suggestion a{
    text-decoration: underline;
    cursor: pointer;
}

.row-1 .input-address:nth-child(3){
    margin-right: 0;
}

.row-2 .input-address:nth-child(4){
    margin-right: 0;
}

.row-3 .input-address:nth-child(3){
    margin-right: 0;
}

.input-address-column{
    width: 50%;
    display: inline-block;
}
  
.input-address-column-small{
    width: 20%;
    display: inline-block;
}

.input-address-column-small-medium{
    width: 22.5%;
    display: inline-block;
}

.input-address-column-medium-large{
    
    width: 45%;
    display: inline-block;
}

.input-address-column-medium{
    width: 35%;
    display: inline-block;
}



@media only screen and (max-width: 660px) {
  
    
    .row-2 .input-address-column-small{
        width: 47.25%;
    }

    .row-2 .input-address-column-small-medium{
        width: 47.25%;
    }

    .row-2 .input-address-column-medium-large{
        
        width: 47.25%;
    }

    .row-2 .input-address-column-medium{
        width: 47.25%;
    }

    
   /*  .row-1 .input-address:nth-child(2){
        margin-right: 0;
    }
 */
    .row-2 .input-address:nth-child(2){
        margin-right: 0;
    }

    .row-3 .input-address:nth-child(3){
        margin-right: 0;
    }

}
  