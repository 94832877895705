@import '../../../style/Variables.scss';

.confirmation-section{
    text-align: center;
    padding: $sectionsPadding;
    background-color: $rowContainerBgColor;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: center;
    font-family: $fontOne;
}

.confirmation-section h1{
    font-family: $fontTwo;
    font-size: 24px;
    font-weight: bold;
    margin-top: 5px;
}

.confirmation-section p{
    margin-bottom: 0;
}

.confirmation-section p:nth-child(even){
    margin-top: 0;
}

.phone-link-inline{
    white-space: nowrap;
    text-decoration: none;
    color: $secondaryTextColor;
}

.confirmation-success-img{
    width: 96px;
    height: 96px;
}
.confirmation-error-img{
    color: red;
    width: 96px;
    height: 96px;
}

.reference-number-container{
    margin-top: 28px;
    margin-left: auto;
    margin-right: auto;
    width: 380px;
    background-color: $mainBgColor;
    padding: 14.5px 12px;
    text-align: left;
    color: $rowContainerTextColor;
    line-height: 100%;
    font-family: $fontOne;
    font-size: 14px;
}


.reference-number-container .column.left-aligned{
    text-align: left;
    font-weight: 700;
}

.reference-number-container .column.right-aligned{
    text-align: right;
}