
/* Dev header */
.dev-links{
    text-align: center;
    padding: 2px 0;
    background-color: #333;
  }
  
  .dev-links a{
    color: white;
    text-decoration: none;
    background-color: #555;
    padding: 0 3px;
    border-radius: 2px;
    margin-right: 5px;
  }
  
  .dev-links span{
    color: white;
    text-decoration: underline;
  }
  /* Dev header - end */